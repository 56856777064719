import { useState } from 'react';
import { X } from 'lucide-react';

import { IconButtonTemplate } from '../../../../../ui/buttons/IconButtonTemplate';
import { AddMandateForm } from '../AddMandateForm';
import { Dialog } from '../../../../../ui/Dialog';

export const MandateItem = ({ mandate = {} }) => {
  const {
    id,
    mandate_number,
    mandate_status,
    mandate_type,
    mandate_start_date,
    mandate_end_date,
  } = mandate;

  const [isOpenAddMandate, setIsOpenAddMandate] = useState(false);

  const handleMandateClick = () => {
    setIsOpenAddMandate(!isOpenAddMandate);
  };

  const handleDeleteClick = () => {};

  return (
    <div
      onClick={handleMandateClick}
      className="flex gap-1 p-1 bg-gray-100 rounded"
    >
      <div className="flex flex-col gap-0.5">
        <span className="block text-sm font-medium text-blackColor">{`№${mandate_number}${mandate_status?.title ? `, ${mandate_status.title}` : ''}`}</span>

        {mandate_type?.title && (
          <span className="block text-xs text-blackColor">
            {mandate_type.title}
          </span>
        )}

        {mandate_start_date && mandate_end_date && (
          <span className="block text-xs text-blackColor">
            {`${mandate_start_date} - ${mandate_end_date}`}
          </span>
        )}
      </div>

      <IconButtonTemplate
        icon={X}
        size={16}
        handleClick={handleDeleteClick}
      />

      {isOpenAddMandate && (
        <Dialog
          content={
            <AddMandateForm
              isEdit={true}
              mandate={mandate}
              handleCancel={() => setIsOpenAddMandate(false)}
            />
          }
          classes="max-w-[90%] sm:max-w-[580px]"
          isOpen={isOpenAddMandate}
          onClose={() => setIsOpenAddMandate(false)}
        />
      )}
    </div>
  );
};
