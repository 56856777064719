import { api } from '../api';
import { links } from '../../../data/links';

export const mandateApi = api.injectEndpoints({
  endpoints: build => ({
    getMandates: build.query({
      query: token => ({
        url: links.mandate,
        headers: { Authorization: `Bearer ${token}` },
      }),
      transformResponse: responseData => {
        const mandateData = responseData?.data;

        if (mandateData) {
          return mandateData;
        } else {
          return [];
        }
      },
      providesTags: result =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'Mandate', id })),
            { type: 'Mandate', id: 'LIST' },
          ]
          : [{ type: 'Mandate', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetMandatesQuery,
} = mandateApi;
