export const links = {
  auth: 'api/auth',
  registerData: 'api/auth/register-data',
  register: 'api/auth/register',
  login: 'api/auth/login',
  logout: 'api/auth/logout',
  realty: 'api/realty',
  mandate: 'api/realty/mandate/list',
  realties: 'api/realty',
  clients: 'api/clients',
  contacts: 'api/contacts',
  requests: 'api/client-requests',
  agents: 'api/agents',
  agentsResults: 'api/agent-results',
  agentsAssistants: 'api/agent-assistant',
  collaborations: 'api/collaborations',
  visits: 'api/visits',
  cities: 'api/cities',
  districts: 'api/districts',
  streets: 'api/streets',
  streetsNumber: 'api/street-numbers',
  residences: 'api/residences',
  documents: 'api/documents',
  notifications: 'api/notifications',
  filters: 'api/filters',
  search: 'api/search',
  searchMap: 'api/search/map',
  photos: 'api/photo',
  videos: 'api/video',
  likes: 'api/likes',
  reminders: 'api/reminders',
  emails: 'api/email-newsletter',
};
