import { mockAccordionTitles } from '../../../data/constants';

export const equipmentInitialValues = {
  facilities: [],
  buildings: [],
  securities: [],
  sports: [],
  exterior_spaces: [],
};

const mockTitle = mockAccordionTitles.Equipment;

export const equipmentMock = [
  {
    title: mockTitle,
    subtitle: 'Facilities',
    id: 1,
    fieldType: 'checkboxList',
    name: 'facilities',
    checkboxes: [
      {
        checkboxText: 'Water softener',
        value: 1,
        name: 'facilities_water_softener',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Air conditioning',
        value: 2,
        name: 'facilities_conditioning',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Central vacuum',
        value: 3,
        name: 'facilities_vacuum',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Spa bath',
        value: 4,
        name: 'facilities_spa',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Fireplace',
        value: 5,
        name: 'facilities_fireplace',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Smart home',
        value: 6,
        name: 'facilities_smart_home',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Double glazing',
        value: 7,
        name: 'facilities_glazing',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Sliding windows',
        value: 8,
        name: 'facilities_sliding_windows',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Internet',
        value: 9,
        name: 'facilities_internet',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Jacuzzi',
        value: 10,
        name: 'facilities_jacuzzi',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Caretaker`s house',
        value: 11,
        name: 'facilities_caretaker_house',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Furnished',
        value: 12,
        name: 'facilities_furnished',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Awning',
        value: 13,
        name: 'facilities_awning',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Electric awning',
        value: 14,
        name: 'facilities_electric_awning',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Smart thermostat',
        value: 15,
        name: 'facilities_smart_thermostat',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Triple glazing',
        value: 16,
        name: 'facilities_triple_glazing',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Electric shutters',
        value: 17,
        name: 'facilities_shutters',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
    ],
    classNames: 'w-full',
  },
  {
    title: mockTitle,
    subtitle: 'Building',
    id: 2,
    fieldType: 'checkboxList',
    name: 'buildings',
    checkboxes: [
      {
        checkboxText: 'Access for people with reduced mobility',
        value: 1,
        name: 'buildings_access',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Elevator',
        value: 2,
        name: 'buildings_elevator',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Laundry',
        value: 3,
        name: 'buildings_laundry',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Fiber optics',
        value: 4,
        name: 'buildings_fiber_optics',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Satellite dish',
        value: 5,
        name: 'buildings_satellite_dish',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Videophone',
        value: 6,
        name: 'buildings_videophone',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Cinema',
        value: 7,
        name: 'buildings_cinema',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Wine cellar',
        value: 8,
        name: 'buildings_wine_cellar',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
    ],
    classNames: 'w-full',
  },
  {
    title: mockTitle,
    subtitle: 'Security',
    id: 3,
    fieldType: 'checkboxList',
    name: 'securities',
    checkboxes: [
      {
        checkboxText: 'Alarm',
        value: 1,
        name: 'securities_alarm',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Safe',
        value: 2,
        name: 'securities_safe',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Digicode',
        value: 3,
        name: 'securities_digicode',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Guard',
        value: 4,
        name: 'securities_fiber_optics',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Interphone',
        value: 5,
        name: 'securities_interphone',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Electric gates',
        value: 6,
        name: 'securities_electric_gates',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Armored door',
        value: 7,
        name: 'securities_armored_door',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Video surveillance',
        value: 8,
        name: 'securities_surveillance',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
    ],
    classNames: 'w-full',
  },
  {
    title: mockTitle,
    subtitle: 'Sport facilities',
    id: 4,
    fieldType: 'checkboxList',
    name: 'sports',
    checkboxes: [
      {
        checkboxText: 'Golf',
        value: 1,
        name: 'sports_golf',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Lawn bowls',
        value: 2,
        name: 'sports_lawn_bowls',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Swimming pool',
        value: 3,
        name: 'sports_pool',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Sauna',
        value: 4,
        name: 'sports_sauna',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Spa',
        value: 5,
        name: 'sports_spa',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Tennis',
        value: 6,
        name: 'sports_tennis',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Playground',
        value: 7,
        name: 'sports_playground',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Ping-pong',
        value: 8,
        name: 'sports_pinpong',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Gym',
        value: 9,
        name: 'sports_gym',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
    ],
    classNames: 'w-full',
  },
  {
    title: mockTitle,
    subtitle: 'Exterior spaces',
    id: 5,
    fieldType: 'checkboxList',
    name: 'exterior_spaces',
    checkboxes: [
      {
        checkboxText: 'Carport',
        value: 1,
        name: 'exterior_spaces_carport',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Automatique irrigation',
        value: 2,
        name: 'exterior_spaces_irrigation',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Barbeque',
        value: 3,
        name: 'exterior_spaces_barbeque',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'External lighting',
        value: 4,
        name: 'exterior_spaces_light',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Well',
        value: 5,
        name: 'exterior_spaces_well',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Heliport',
        value: 6,
        name: 'exterior_spaces_heliport',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Water well',
        value: 7,
        name: 'exterior_spaces_water_well',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Fountain',
        value: 8,
        name: 'exterior_spaces_fountain',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Patio',
        value: 9,
        name: 'exterior_spaces_patio',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
      {
        checkboxText: 'Pool house',
        value: 10,
        name: 'exterior_spaces_pool_house',
        fieldType: 'checkbox',
        classNames: 'w-max',
      },
    ],
    classNames: 'w-full',
  },
];
