import { useEffect, useState } from 'react';

import { Menu } from 'lucide-react';

import { ButtonTemplate } from '../../../../../ui/buttons/ButtonTemplate';
import { Dialog } from '../../../../../ui/Dialog';
import { MandateTable } from '../../../../../ui/MandateTable';
import { AddMandateForm } from '../AddMandateForm';
import { MandateItem } from '../MandateItem';

const mandatesHardCode = [
  {
    id: 1,
    realty_id: 1,
    mandate_number: 22,
    mandate_status: {
      title: 'Sent for signature',
      value: 1,
    },
    mandate_type: {
      title: 'Exclusive',
      value: 4,
    },
    mandate_start_date: '06-06-2024',
    mandate_end_date: '30-11-2024',
  },
  {
    id: 2,
    realty_id: 1,
    mandate_number: 22,
    mandate_status: {
      title: 'Sent for signature',
      value: 1,
    },
    mandate_type: {
      title: 'Exclusive',
      value: 4,
    },
    mandate_start_date: '06-06-2024',
    mandate_end_date: '30-11-2024',
  },
];

export const MandateBlockForm = ({ formProps = {}, inputsList = [] }) => {
  const [mandates, setMandates] = useState([]);
  const [isOpenAddMandate, setIsOpenAddMandate] = useState(false);
  const [isOpenMandateList, setIsOpenMandateList] = useState(false);

  const handleDeleteClick = () => {
    inputsList.forEach(input => {
      if (formProps?.setFieldValue) {
        formProps.setFieldValue(input.name, '');
      }
    });
  };

  const handleAddExistedMandate = mandateId => {};

  useEffect(() => {
    setMandates(mandatesHardCode);
  }, []);

  return (
    <>
      <div className="flex items-center flex-wrap gap-3">
        <ButtonTemplate
          text={'All mandates'}
          handleClick={() => setIsOpenMandateList(true)}
          icon={Menu}
          isIconText={true}
        />

        <ButtonTemplate
          text={'Add new'}
          handleClick={() => setIsOpenAddMandate(true)}
        />

        <ButtonTemplate
          text={'Add existing'}
          handleClick={() => setIsOpenMandateList(true)}
        />
      </div>

      <div className="flex flex-wrap gap-2">
        {mandates.map(mandate => {
          return (
            <MandateItem
              key={mandate.id}
              mandate={mandate}
            />
          );
        })}
      </div>

      {isOpenAddMandate && (
        <Dialog
          content={
            <AddMandateForm handleCancel={() => setIsOpenAddMandate(false)} />
          }
          classes="max-w-[90%] sm:max-w-[810px]"
          isOpen={isOpenAddMandate}
          onClose={() => setIsOpenAddMandate(false)}
        />
      )}

      {isOpenMandateList && (
        <Dialog
          content={
            <MandateTable handleAddExistedMandate={handleAddExistedMandate} />
          }
          classes="max-w-[90%] sm:max-w-[810px]"
          isOpen={isOpenMandateList}
          onClose={() => setIsOpenMandateList(false)}
        />
      )}
    </>
  );
};
