import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { FormTemplate } from '../../../FormTemplate';
import { InputsTemplate } from '../../../../inputs/InputsTemplate';

import { showError } from '../../../../../../utils/ui';
import {
  validationSchemaMandate,
  mandateMockValues,
  mandateMock,
} from '../../../../../../lib/mocks/add-realty-mock/mandate-mock';
import {
  getDefaultValues,
  getInitialValues,
} from '../../../../../../utils/data';

export const AddMandateForm = ({
  isEdit = false,
  mandate = {},
  handleCancel = () => {},
}) => {
  const [newInitialValues, setNewInitialValues] = useState({
    ...mandateMockValues,
  });
  const [inputs, setInputs] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    /* setError('');

    let response;
    let errorStatus;

    if (isEdit) {
      response = await editVisit({
        token: userToken,
        id: visit.id,
        data: values,
      });

      errorStatus = await handleResponseError(
        response?.error,
        editingError,
        () => closeDialog({ setState: setShowAddVisitForm })
      );
    } else {
      response = await addNewVisit({
        token: userToken,
        data: values,
        realty_id: realtyId, //hardcode
      });

      errorStatus = await handleResponseError(
        response?.error,
        addingError,
        () => closeDialog({ setState: setShowAddVisitForm })
      );
    }

    setError(errorStatus); */
  };

  const updateMock = () => {
    if (isEdit) {
      setNewInitialValues(prevValues => getInitialValues(mandate, prevValues));

      const updatedMock = getDefaultValues(mandate, mandateMock);

      setInputs(updatedMock);
    } else {
      setNewInitialValues(mandateMockValues);
      setInputs(mandateMock);
    }
  };

  useEffect(() => {
    showError(error);
  }, [error]);

  useEffect(() => {
    updateMock();
  }, []);

  return (
    <div className="flex flex-col gap-4 w-[260px] sm:w-[420px] exclude-click">
      <FormTemplate
        initialValues={newInitialValues}
        validationSchema={Yup.object(validationSchemaMandate)}
        handleSubmitForm={handleSubmit}
        buttonText={'Save'}
        bgColor={'bg-purpleColor border-purpleColor'}
      >
        {formProps => (
          <InputsTemplate
            formProps={formProps}
            inputsList={inputs}
          />
        )}
      </FormTemplate>
    </div>
  );
};
