import { mockAccordionTitles } from '../../../data/constants';

export const tarifsMockValues = {
  tariff_netto_price: '',
  tariff_commission: '',
  tariff_price: '',
  tariff_price_modified: '',
  tariff_is_hide_price: '',
  tariff_is_discount: '',
  tariff_notary: '',
  tariff_notary_city: '',
  tariff_agency_estimation_price: '',
  tariff_agency_negotiation_price: '',
  //tariff_property_price: '',
  tariff_housing_tax: '',
  tariff_condominium_fees: '',
  tariff_property_tax: '',
};

const mockTitle = mockAccordionTitles.Tariff;

export const tarifsMock = [
  {
    title: mockTitle,
    id: 1,
    checkboxText: 'Modified price!',
    name: 'tariff_price_modified',
    fieldType: 'checkbox',
    classNames: 'w-60 sm:w-80',
  },
  {
    title: mockTitle,
    listId: 'listId7',
    id: 2,
    placeholder: 'Netto price',
    name: 'tariff_netto_price',
    isReadOnly: true,
    fieldType: 'text',
    type: 'number',
    classNames: 'w-[47%] sm:w-[30%]',
  },
  {
    title: mockTitle,
    listId: 'listId7',
    id: 3,
    placeholder: 'Comission (%)',
    name: 'tariff_commission',
    fieldType: 'text',
    type: 'number',
    classNames: 'w-[47%] sm:w-[30%]',
  },
  {
    title: mockTitle,
    listId: 'listId7',
    id: 4,
    placeholder: 'Notary tariff',
    name: 'tariff_notary',
    fieldType: 'text',
    type: 'number',
    classNames: 'w-[47%] sm:w-[30%]',
  },
  {
    title: mockTitle,
    listId: 'listId7',
    id: 5,
    placeholder: 'Notary city',
    name: 'tariff_notary_city',
    fieldType: 'text',
    classNames: 'w-[47%] sm:w-[30%]',
  },
  {
    title: mockTitle,
    listId: 'listId8',
    id: 6,
    placeholder: '€ Market Price',
    name: 'tariff_price',
    fieldType: 'text',
    type: 'number',
    classNames: 'w-[47%] sm:w-[30%]',
  },
  {
    title: mockTitle,
    listId: 'listId8',
    id: 7,
    checkboxText: 'Hide price',
    name: 'tariff_is_hide_price',
    fieldType: 'checkbox',
    classNames: 'w-max',
  },
  {
    title: mockTitle,
    listId: 'listId8',
    id: 8,
    checkboxText: 'As discount',
    name: 'tariff_is_discount',
    fieldType: 'checkbox',
    classNames: 'w-max',
  },
  {
    title: mockTitle,
    inputsTitle: 'Negotiation',
    id: 9,
    listId: 'listId9',
    placeholder: 'Agency estimation price',
    name: 'tariff_agency_estimation_price',
    fieldType: 'text',
    type: 'number',
    classNames: 'w-[47%]',
  },
  {
    title: mockTitle,
    id: 10,
    listId: 'listId9',
    placeholder: 'Agency negotiation price',
    name: 'tariff_agency_negotiation_price',
    fieldType: 'text',
    type: 'number',
    classNames: 'w-[47%]',
  },
  {
    title: mockTitle,
    inputsTitle: 'Taxes',
    id: 11,
    listId: 'listId10',
    placeholder: 'Property tax',
    name: 'tariff_property_tax',
    fieldType: 'text',
    type: 'number',
    classNames: 'w-[47%]',
  },
  {
    title: mockTitle,
    id: 12,
    listId: 'listId10',
    placeholder: 'Housing tax',
    name: 'tariff_housing_tax',
    fieldType: 'text',
    type: 'number',
    classNames: 'w-[47%]',
  },
  {
    title: mockTitle,
    id: 13,
    listId: 'listId10',
    placeholder: 'Condominium fees/mois',
    name: 'tariff_condominium_fees',
    fieldType: 'text',
    type: 'number',
    classNames: 'w-[47%]',
  },
];
